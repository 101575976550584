<template>
  <div>
    <h3 class="mb-3">{{ $t('categories.uzb') }}</h3>
    <b-row>
      <b-col cols="12" class="my-3">
        <card-item size="vertical-md" has-description />
      </b-col>
      <b-col cols="12" md="6" class="my-3" v-for="news in 20" :key="news">
        <card-item />
      </b-col>
    </b-row>
    <button class="button button--default button--block mt-4">
      {{ $t('upload_news') }}
    </button>
  </div>
</template>

<script>
import CardItem from '@/components/cards/CardItem'

export default {
  components: {
    CardItem
  }
}
</script>
